/* eslint-disable no-underscore-dangle */
/* eslint-disable eqeqeq */
import Router from 'next/router';
import { removeIsLoginFlagging } from '@helper_auth';
import { removeCartId } from '@helper_cartid';
import Cookies from 'js-cookie';
import { useApolloClient } from '@apollo/client';
import { localTotalCart } from '@services/graphql/schema/local';
import {
    custDataNameCookie, features, expiredDefault,
} from '@config';
import { setCookies } from '@helper_cookies';
import {
    getCategories, getCustomer, removeToken, getVesMenu,
    setSearchTerm as mutationSearchTerm, caCheckCustomerB2B,
} from '../../../services/graphql';

const CoreTopNavigation = (props) => {
    const {
        Content, storeConfig, t, isLogin, coupon, show, setShow,
    } = props;
    const [value, setValue] = React.useState('');
    const [deleteTokenGql] = removeToken();

    const [setSearchTerm] = mutationSearchTerm();
    const { data, loading } = features.vesMenu.enabled ? getVesMenu({
        variables: {
            alias: features.vesMenu.alias,
        },
    }) : getCategories();
    const b2bData = Cookies.getJSON('customerB2B');

    let customerData = {};
    let customerLoading = false;
    let b2bLoading = false;
    if (isLogin && isLogin === 1 && typeof window !== 'undefined') {
        const customer = getCustomer();
        customerLoading = customer.loading;
        if (customer.data) {
            customerData = customer.data;
        }
        const customerB2B = caCheckCustomerB2B();
        b2bLoading = customerB2B.loading;
        if (customerB2B.data) {
            Cookies.set('customerB2B', customerB2B.data.caCheckCustomerB2B, { expires: expiredDefault });
        }
    }
    const client = useApolloClient();

    const handleLogout = () => {
        deleteTokenGql().then(() => {
            if (storeConfig?.enabled_clickstream === '1' && typeof window !== 'undefined' && window?._swan?.logout) {
                // eslint-disable-next-line no-underscore-dangle
                window._swan.logout();
            }
            Cookies.remove(custDataNameCookie);
            Cookies.set('customerB2B', { status: false }, { expires: expiredDefault });
            setCookies('coupon', []);
            removeIsLoginFlagging();
            removeCartId();
            localStorage.removeItem('xdt_customer_id');
            client.writeQuery({ query: localTotalCart, data: { totalCart: 0 } });
            Router.push(
                Router.pathname == '/'
                    ? '/customer/account/login'
                    : '/',
            );
        }).catch(() => {
            //
        });
    };

    const handleSearch = (ev) => {
        if (ev.key === 'Enter' && ev.target.value !== '') {
            if (storeConfig?.enabled_clickstream === '1' && typeof window !== 'undefined' && window?._swan?.search) {
                // eslint-disable-next-line no-underscore-dangle
                window._swan.search({ searchKeyword: value });
            }
            setSearchTerm({
                variables: {
                    searchTerm: value,
                },
            }).then(() => {
                Router.push({
                    pathname: '/catalogsearch/result',
                    query: { q: value },
                });
            });
        }
    };

    const searchByClick = () => {
        if (value !== '') {
            if (storeConfig?.enabled_clickstream === '1' && typeof window !== 'undefined' && window?._swan?.search) {
                window._swan.search({ searchKeyword: value });
            }
            setSearchTerm({
                variables: {
                    searchTerm: value,
                },
            }).then(() => {
                Router.push({
                    pathname: '/catalogsearch/result',
                    query: { q: value },
                });
            });
        }
    };

    return (
        <Content
            t={t}
            isLogin={isLogin}
            data={data}
            b2bData={b2bData}
            loading={loading}
            storeConfig={storeConfig}
            handleSearch={handleSearch}
            searchByClick={searchByClick}
            setValue={setValue}
            customer={customerData}
            handleLogout={handleLogout}
            value={value}
            coupon={coupon}
            customerLoading={customerLoading || b2bLoading}
            show={show}
            setShow={setShow}
        />
    );
};

export default CoreTopNavigation;
