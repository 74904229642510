/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { features } from '@config';

export const categories = gql`
    {
        categoryList {
            children_count
            children {
                id
                level
                name
                path
                url_path
                url_key
                include_in_menu
                children {
                    id
                    level
                    name
                    path
                    url_path
                    url_key
                    image
                    image_path
                    children {
                        id
                        level
                        name
                        path
                        url_path
                        url_key
                        children {
                            id
                            level
                            name
                            path
                            url_path
                            url_key
                        }
                    }
                }
            }
        }
    }
`;

export const getCmsBlocks = gql`
    query($identifiers: [String]) {
        cmsBlocks(identifiers: $identifiers) {
            items {
                identifier
                title
                content
            }
        }
    }
`;

export const getCustomer = gql`
    {
        customer {
        id
        firstname
        lastname
        email
        whatsapp_number
        transaction_count
        }
        wishlist {
        items {
            id
        }
        }
    }
`;

export const removeToken = gql`
mutation {
  internalDeleteCustomerToken{
    result
  }
}
`;

export const vesMenu = gql`
    query getVesMenu(
        $alias: String!
    ) {
        vesMenu(
          alias: $alias
        )
        {
          menu_id
          name
          items {
            id
            name
            link
            content_type
            link_type
            category_id
            menu_id
            children {
              id
              name
              link
              link_type
              category_id
              dropdown_bgimage
              children {
               id
               name
               link
               link_type
               category_id
               children {
                id
                name
                link
                link_type
                category_id
                }
              }
            }
          }
        }
      }
`;

/**
 * scema dynamic resolver url
 * @param url String
 * @returns grapql query
 */

export const getProduct = (key) => {
    const query = gql`{
        products(
            search: "${key}",
            pageSize: 5
          ) {
            items {
                id
                name
                url_key
                small_image {
                    url(width: ${features.imageSize.product.width}, height: ${features.imageSize.product.height})
                    label
                    url_original
                }
                price_range{
                    minimum_price {
                        final_price{
                            currency
                            value
                        }
                    }
                }
            }
            total_count
          }
    }`;
    return query;
};

/**
 * scema dynamic resolver url
 * @param name String
 * @returns grapql query
 */

export const getCategoryByName = (name) => {
    const query = gql`{
        categoryList(filters: { name: { match: "${name}" } }) {
            id
            name
            url_key
            url_path
            __typename
            breadcrumbs {
                category_id
                category_level
                category_name
                category_url_key
                category_url_path
            }
        }
      }`;
    return query;
};

export const getStoreSwitcher = gql`
    query storeSwitcher(
        $longitude: String,
        $latitude: String
    ){
        storeSwitcher(longitude: $longitude, latitude: $latitude) {
            default_vendor_id
            options {
                vendor_id
                vendor_name
            }
        }
    }
`;

export const countCompare = gql`
  query countCompare($uid: String){
    getCountProductCompare(uid: $uid){
      count
  }
}
`;

export const popularTerms = gql`
    query popularTerms($pageSize: Int!, $currentPage: Int!) {
        popularTerms(pageSize: $pageSize, currentPage: $currentPage) {
        termList {
            popularity
            search_query
        }
        total_count
        total_pages
        }
    }
`;

export const popularCategory = gql`
    query getPopularCategory($pageSize: Int!, $currentPage: Int!) {
        getPopularCategory(pageSize: $pageSize, currentPage: $currentPage) {
        popularCategory {
            id
            name
            url_key
            image_icon
        }
        }
    }
`;

export const setSearchTerm = gql`
    mutation setQuerySearchTerm($searchTerm: String!) {
        setQuerySearchTerm(input: { search_term: $searchTerm }) {
        status
        }
    }
`;

export const caCheckCustomerB2B = gql`
query {
    caCheckCustomerB2B {
        status
        roles {
            role
        }
        status_company
    }
}`;

export default {
    categories,
    getCustomer,
    removeToken,
    vesMenu,
    getProduct,
    getCategoryByName,
    getStoreSwitcher,
    countCompare,
    popularTerms,
    popularCategory,
    setSearchTerm,
    caCheckCustomerB2B,
};
